

.navbar_links {
    flex: 1;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;

    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 1.2rem;

    background: #09253b;


}

.logo_css{
    height: 75px;
    width: 225px;
}

.logo_text{
    margin-left: 20px;
    font-weight: 800;
    font-size: 2.8rem;
    font-family: Montserrat;
    background: #09253b;
    color: white;
    font-style: italic;
}

.logo_css:hover{
    cursor: pointer;
}

.navbar_container{
    display: flex;
    flex-wrap: wrap;
    flex-flow: column wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 10px;

    background: #09253b;
}

a{
    text-decoration: none;
    color: white;
}

.title_not_link{
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
}
a:hover{
    cursor: pointer;
}

.active{
    color: #ffffff;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.language_button{
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
    background-color: #09253b;
    border: none;
    padding: 2px;
}

.language_button:hover{
    cursor: pointer;
}
