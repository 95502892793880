.plan_container{
    display: flex;
    gap: 50px;
    justify-content: center;
    max-width: 800px;
    flex-wrap: wrap;
}

.plan_parent_container{
    display: flex;
    justify-content: center;
}

.plan_dimensions{
    width: 350px;
    height: 350px;
    border: lightgrey;
    border-style: solid;
    border-width: 1px 1px; /* 20px top and bottom, 5px on the sides */
    border-radius: 5px;
}

.plan_text{
    font-weight: 400;
    letter-spacing: 0.02rem;
    font-family: Montserrat, sans-serif;
    text-align: center;
    color: #09253b;
    font-size: 1.2rem;
    margin: 5px;
}